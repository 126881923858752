import styled from "styled-components";
import { NavLink } from "react-router-dom";
export const Button = styled.button``;

export const Nav = styled.nav`
  margin-top: 49px;
  width: ${(props) => (props.isopen === "true" ? "196px" : "64px")};
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f1f5f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  transition: width 0.2s ease-in-out;
  overflow: hidden;
  z-index: ${(p) => p.zindex && p.zindex};
  border-right: 1px solid rgba(46, 46, 46, 0.25);
  font-family: Open Sans, sans-serif;
  &:hover {
    z-index: 1052 !important;
  }
  overflow: ${(props) =>
    props.isopen === "true"
      ? props.isOnPatientDashboard
        ? "hidden"
        : "auto"
      : "hidden"};
`;

export const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  color: #2e2e2e80;
  color: ${(p) => (p.color ? p.color : "#2e2e2e80")};
  flex-direction: ${(props) => (props.isopen === "true" ? "row" : "column")};
  text-decoration: none;
  width: 100%;
  padding: 24px 0px 0px 0px;
  &:hover {
    cursor: pointer;
    color: #2c7be5;
  }
  span {
    margin-left: 8px;
    transition: opacity 0.2s ease-in-out, max-width 0.2s ease-in-out;
    opacity: ${(props) => (props.isopen === "true" ? "1" : "0")};
    display: ${(props) => (props.isopen === "true" ? "block" : "none")};
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &:hover {
      color: #2c7be5;
    }
  }
  svg {
    font-size: 19px;
  }
`;
export const SubMenu = styled.ul`
  text-align: ${(p) => p.textAlign && p.textAlign};
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  li {
    padding: 8px;
    margin: 0;
    /* &:hover {
      background: #fff;
    } */
  }
  width: ${(p) => p.width && p.width};
`;
export const TopIcon = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  color: #000000;
  padding: 24px 0px 0px 20px;
  &:hover {
    cursor: pointer;
  }
`;
export const Hr = styled.hr`
  border: none;
  border-right: 1px solid rgba(46, 46, 46, 0.25);
  height: 100vh;
  width: 1px;
`;

export const Div = styled.div`
  display: ${(p) => p.display && p.display};
  cursor: ${(p) => p.cursor && p.cursor};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin: ${(p) => p.margin && p.margin};
  text-align: ${(p) => p.textAlign && p.textAlign};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  color: ${(p) => p.color && p.color};
  align-items: ${(p) => p.alignItems && p.alignItems};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  border: ${(p) => p.border && p.border};
  position: ${(p) => p.position && p.position};
  bottom: ${(p) => p.bottom && p.bottom};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  // padding-top: ${(p) => p.paddingTop && p.paddingTop};
  // padding: ${(p) => p.padding && p.padding};
  // padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  svg {
    font-size: ${(p) => p.svgFontSize && p.svgFontSize};
    margin-left: ${(p) => p.svgMarginLeft && p.svgMarginLeft};
    margin-top: ${(p) => p.svgMarginTop && p.svgMarginTop};
    width: ${(p) => p.svgWidth && p.svgWidth};
    height: ${(p) => p.svgHeight && p.svgHeight};
  }
  .svgSize {
    width: 1.79em;
    height: 1.79em;
  }
`;
export const SubNavItem = styled(NavLink)`
  color: #2e2e2e80;
  text-decoration: none;
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  font-size: ${(p) => p.fontSize && p.fontSize};
  width: 100%;
  &.active {
    color: #2c7be5;
    font-weight: 600;
  }
`;
export const ListItem = styled.li`
  padding: ${(p) => p.padding && p.padding};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  width: ${(p) => p.width && p.width};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin: ${(p) => p.margin && p.margin};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  &:hover {
    background: ${(p) => p.hoverBackgroundColor && p.hoverBackgroundColor};
  }
`;
export const UL = styled.ul`
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  position: ${(p) => p.position && p.position};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  list-style-type: ${(p) => p.listStyleType && p.listStyleType};
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
  width: ${(p) => p.width && p.width};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  border-right: ${(p) => p.borderRight && p.borderRight};
  height: ${(p) => p.height && p.height};
  border-top: ${(p) => p.borderTop && p.borderTop};
  z-index: ${(p) => p.zIndex && p.zIndex};
  text-align: ${(p) => p.textAlign && p.textAlign};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  overflow: ${(p) => p.overflow && p.overflow};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
`;
export const Span = styled.span`
  color: ${(p) => p.color && p.color};
`;
