import { createSlice } from "@reduxjs/toolkit";

export const RevisionSlice = createSlice({
  name: "Revision",
  initialState: {
    Revision: [],
    RevisionLoading: false,
    FacilitySearchData: [],
    AppointmentSearchData: [],
    FacilitySearchNoResult: "1",
    AppointmentSearchNoResult: "1",
    IncomingFax: {},
    IncomingFaxLoading: false,
    IncomingFaxTableLoading: false,
    OutgoingFaxTableLoading: false,
    SearchIncomingFax: [],
    InsertIncomingFax: [],
    OutgoingFax: {},
    OutgoingFaxLoading: false,
    InsertOutgoingFax: [],
    ProvListFax: [],
    FaxQueue: "",
    previewdownload: "",
    previewdownload_2out: "",
    incomingfaxdetails: [],
    outgoingfaxdetails: [],
    FaxAssign: [],
    FaxProviderdetails: [],
    RefferalIS: false,
    RefferalInsertIS: false,
    RefferalList: [],
    ReferredFrom: [],
    ReferredTo: [],
    ProvidersList: [],
    FaxMailProviders: [],
    PatientSearchList: [],
    ReferralData: [],
    ReferralData1: [],
    GetPreview: [],
    Urlimg: null,
    filterpatient: [],
    fornoresults: "1",
    referrerOptions: [],
    insuranceOptions: [],
    referrerAttachments: [],
    responseAttachments: [],
    singleReferral: [],
    referralLoading: false,
    showModal1: false,
    showModal2: false,
    ReferalContact: [],
    SingleReferalContact: {},
    RefContactemailcheck: "",
    RefContactphonecheck: "",
    RefContactfaxcheck: "",
    RefContactnpicheck: "",
    RefContactemptyvalidation: "",
    RefContactemptyphone: "",
    RefContactemptyfax: "",
    RefContactemptynpi: "",
    sendfaxmail: "",
    ReferralDataOne: [],
    ReferralDownload: "",
    ReferralPreview: "",
    showPreview: false,
    showAdd1: false,
    showAdd2: false,
    modalMesg: "",
    DeleteTempDocumentData: "",
    editId: 0,
    previewId: 0,
    ProviderList: [],
    generalMsg: "",
    disabledSave: false,
    initialLoading: false,
    openTemplate: false,
    sessionForm: {},
    askProvider: false,
    Showcontact: false,
  },
  reducers: {
    GetRevision: (state) => {
      state.RevisionLoading = true;
    },
    SetRevision: (state, action) => {
      state.Revision = action.payload;
      state.RevisionLoading = false;
    },
    GetFacilitySearchData: (Data) => {
      return Data;
    },
    SetFacilitySearchData: (state, action) => {
      state.FacilitySearchData = action.payload;
      state.ScheduleLoading = false;
    },
    SetFacilitySearchNoResult: (state, action) => {
      state.FacilitySearchNoResult = action.payload;
      state.FacilitySearchData = [];
    },
    GetAppointmentSearchData: (Data) => {
      return Data;
    },
    SetAppointmentSearchData: (state, action) => {
      state.AppointmentSearchData = action.payload;
      state.ScheduleLoading = false;
    },
    SetAppointmentTenData(state, action) {
      state.AppointmentSearchData = [
        ...state.AppointmentSearchData,
        ...action.payload,
      ];
    },
    GetNoteUpdateData(state) {
      return state;
    },
    GetIncomingFax: (state) => {
      state.IncomingFaxLoading = true;
      return state;
    },
    SetIncomingFax: (state, action) => {
      state.IncomingFax = action.payload;
      state.IncomingFaxLoading = false;
    },

    GetSearchIncomingFax: (state) => {
      return state;
    },
    SetSearchIncomingFax: (state, action) => {
      state.SearchIncomingFax = action.payload;
    },
    GetInsertIncomingFax: (state) => {
      return state;
    },
    SetInsertIncomingFax: (state, action) => {
      state.InsertIncomingFax = action.payload;
    },
    GetFaxProviderdetails: (state) => {
      return state;
    },
    SetFaxProviderdetails: (state, action) => {
      state.FaxProviderdetails = action.payload;
    },
    GetFaxAssign: (state) => {
      return state;
    },
    SetFaxAssign: (state, action) => {
      state.FaxAssign = action.payload;
    },
    GetOutgoingFax: (state) => {
      state.OutgoingFaxLoading = true;
      return state;
    },
    SetOutgoingFax: (state, action) => {
      state.OutgoingFax = action.payload;
      state.OutgoingFaxLoading = false;
    },

    GetInsertOutgoingFax: (state) => {
      return state;
    },
    SetInsertOutgoingFax: (state, action) => {
      state.InsertOutgoingFax = action.payload;
    },

    GetProvDataFax(state) {
      return state;
    },
    SetProvDataFax(state, action) {
      state.ProvListFax = action.payload;
      state.ScheduleLoading = false;
    },
    GetSaveFaxQueue(state) {
      return state;
    },
    SetSaveFaxQueue(state, action) {
      state.FaxQueue = action.payload;
      state.ScheduleLoading = false;
    },
    GetEmptyProviderList(state) {
      return state;
    },
    GetIncomingFaxDetails: (state) => {
      state.IncomingFaxTableLoading = true;
      return state;
    },
    SetIncomingFaxDetails: (state, action) => {
      state.incomingfaxdetails = action.payload;
      state.IncomingFaxTableLoading = false;
    },
    GetOutgoingFaxDetails: (state) => {
      state.OutgoingFaxTableLoading = true;
      return state;
    },
    SetOutgoingFaxDetails: (state, action) => {
      state.outgoingfaxdetails = action.payload;
      state.OutgoingFaxTableLoading = false;
    },
    GetPreviewDownload: (filename) => {
      return filename;
    },
    setPreviewDownload: (state, action) => {
      state.previewdownload = action.payload;
      state.Loading = false;
    },
    setPreviewDownloadEmpty: (state, action) => {
      state.previewdownload = "";
    },

    GetPreviewDownload_2out: (filename) => {
      return filename;
    },
    setPreviewDownload_2out: (state, action) => {
      state.previewdownload_2out = action.payload;
      state.Loading = false;
    },
    setPreviewDownload_2outEmpty: (state, action) => {
      state.previewdownload_2out = "";
    },
    GetEmptyProviderListReferal(state) {
      return state;
    },
    GetRefferal: (state) => {
      state.RefferalInsertIS = true;
    },
    SetRefferal: (state, action) => {
      state.RefferalList = action.payload;
      state.RefferalInsertIS = false;
    },
    GetInsertRefferal: (state) => {
      return state;
    },
    getProvidersList: (state) => {
      return state;
    },
    setProvidersList(state, action) {
      state.ProvidersList = action.payload;
    },
    getFaxMailProviders(state) {
      return state;
    },
    setFaxMailProviders(state, action) {
      state.FaxMailProviders = action.payload;
    },
    getPatList(state, action) {
      return state;
    },
    setPatList(state, action) {
      state.PatientSearchList = action.payload;
    },
    getReferalData(state) {
      state.filterLoading = true;
    },
    setReferalData(state, action) {
      const { type, data } = action.payload;
      state.filterLoading = false;
      if (type === "1") {
        state.ReferralData = data;
      } else {
        state.ReferralData1 = data;
      }
    },

    setTenReferalData(state, action) {
      state.ReferralData = [...state.ReferralData, ...action.payload.data];
    },
    getReferalData1(state) {
      state.filterLoading = true;
    },
    setReferalData1(state, action) {
      state.filterLoading = false;
      state.ReferralData1 = action.payload;
    },

    setTenReferalData1(state, action) {
      state.ReferralData1 = [...state.ReferralData1, ...action.payload];
    },
    GetRefMngmtPreview: (state) => {
      state.RevisionLoading = false;
    },
    GetRefferalDelete(state) {
      return state;
    },
    SetRefMngmtPreview: (state, action) => {
      state.GetPreview = action.payload;
      state.RevisionLoading = false;
    },

    GetUrlimg: (details) => {
      return details;
      //state.RevisionLoading = false;
    },
    SetUrlimg: (state, action) => {
      state.Urlimg = action.payload;
      state.RevisionLoading = false;
    },
    getSeachProvider: (details) => {
      return details;
    },
    setNoResult: (state, action) => {
      state.fornoresults = action.payload;
    },
    setProviderList: (state, action) => {
      const { type, data } = action.payload;
      if (type === "from") {
        state.ReferredFrom = data !== null ? data : [];
      } else if (type === "to") {
        state.ReferredTo = data !== null ? data : [];
      } else if (type === "initial") {
        state.ReferredFrom = data[0] !== null ? data[0] : [];
        state.ReferredTo = data[1] !== null ? data[1] : [];
      }
    },
    getReferrerDiagnosis: (details) => {
      return details;
    },
    setReferrerDiagnosis: (state, action) => {
      state.referrerOptions = action.payload;
    },
    getPatientDetails: (data) => {
      return data;
    },
    setPatientDetails: (state, action) => {
      state.insuranceOptions =
        action.payload[0] && action.payload[0] !== null
          ? action.payload[0]
          : [];
      state.referrerAttachments =
        action.payload[1] && action.payload[1] !== null
          ? action.payload[1]
          : [];
      state.responseAttachments =
        action.payload[2] && action.payload[2] !== null
          ? action.payload[2]
          : [];
    },
    referralInsertStart: (state) => {
      state.referralLoading = true;
    },
    getSingleReferral: (state) => {
      state.initialLoading = true;
    },
    startUpdateReferral: (state) => {
      state.referralLoading = true;
    },
    setSingleReferral: (state, action) => {
      state.initialLoading = false;
      state.singleReferral = action.payload !== null ? action.payload : [];
    },
    changeDisableSave: (state, action) => {
      state.disabledSave = action.payload;
    },
    changeAlertModal: (state, action) => {
      const { type, value, mesg, preview, show } = action.payload;
      state.referralLoading = false;
      state.disabledSave = false;
      if (type === 1) {
        state.showPreview = show;
        state.showModal1 = value;
        state.modalMesg = mesg;
        state.previewId = preview;
      } else if (type === 2) {
        if (!value) {
          state.showAdd1 = false;
          state.showAdd2 = false;
        }
        state.showModal2 = value;
        state.modalMesg = mesg;
      }
    },

    GetReferalContact: (state) => {
      return state;
    },
    SetReferalContact: (state, action) => {
      state.ReferalContact = action.payload;
    },
    SetTenReferalContact(state, action) {
      state.ReferalContact = [...state.ReferalContact, ...action.payload];
    },

    GetInsertReferalContact: (state) => {
      return state;
    },
    GetSingleReferalContact: (state) => {
      return state;
    },
    SetSingleReferalContact: (state, action) => {
      state.SingleReferalContact = action.payload;
    },
    GetUpdateReferalContact: (state) => {
      return state;
    },
    GetDeleteReferalContact: (state) => {
      return state;
    },

    GetRefContactemailcheck: (state) => {
      return state;
    },
    SetRefContactemailcheck: (state, action) => {
      state.RefContactemailcheck = action.payload;
    },
    GetRefContactphonecheck: (state) => {
      return state;
    },
    SetRefContactphonecheck: (state, action) => {
      state.RefContactphonecheck = action.payload;
    },
    GetRefContactfaxcheck: (state) => {
      return state;
    },
    SetRefContactfaxcheck: (state, action) => {
      state.RefContactfaxcheck = action.payload;
    },
    GetRefContactnpicheck: (state) => {
      return state;
    },
    SetRefContactnpicheck: (state, action) => {
      state.RefContactnpicheck = action.payload;
    },
    GetRefContactemptyvalidation: (state) => {
      return state;
    },
    SetRefContactemptyvalidation: (state, action) => {
      state.RefContactemailcheck = action.payload;
    },
    SetRefContactemptyphone: (state, action) => {
      state.RefContactphonecheck = action.payload;
    },

    SetRefContactemptyfax: (state, action) => {
      state.RefContactfaxcheck = action.payload;
    },
    SetRefContactemptynpi: (state, action) => {
      state.RefContactnpicheck = action.payload;
    },

    getSendFaxMail: (details) => {
      return details;
      //state.RevisionLoading = false;
    },
    setSendFaxMail: (state, action) => {
      state.sendfaxmail = action.payload;
      state.RevisionLoading = false;
    },
    getReferalDataOne: (details) => {
      return details;
    },
    setReferalDataOne: (state, action) => {
      state.ReferralDataOne = action.payload;
      state.RevisionLoading = false;
    },
    getReferralDownload: (details) => {
      return details;
    },
    setReferralDownload: (state, action) => {
      state.ReferralDownload = action.payload;
      state.RevisionLoading = false;
    },

    getReferralPreview: (details) => {
      return details;
    },
    setReferralPreview: (state, action) => {
      state.ReferralPreview = action.payload;
      state.RevisionLoading = false;
    },
    emptyReferralDownload: (details) => {
      return details;
    },
    getDeleteTempDocumentData: (details) => {
      return details;
    },
    setDeleteTempDocumentData: (state, action) => {
      state.DeleteTempDocumentData = action.payload;
      state.RevisionLoading = false;
    },
    changeShowAdd: (state, action) => {
      const { value, id, type } = action.payload;
      if (type) {
        if (type === "out") {
          state.showAdd1 = value;
        } else {
          state.showAdd2 = value;
        }
      }

      state.editId = id;
    },
    changeShowcontact: (state, action) => {
      state.Showcontact = action.payload;
    },
    changePreview: (state, action) => {
      const { value1, value2, type } = action.payload;
      state.showPreview = value1;
      if (type === "Out") {
        state.showAdd1 = value2;
      } else {
        state.showAdd2 = value2;
      }
    },
    clearReferral: (state) => {
      state.filterpatient = [];
      state.referrerOptions = [];
      state.insuranceOptions = [];
      state.referrerAttachments = [];
      state.responseAttachments = [];
      state.singleReferral = [];
      state.referralLoading = false;
      state.disabledSave = false;
      state.editId = 0;
    },
    GetMsgList: (state) => {
      state.Loading = true;
    },
    SetGetMsgList: (state, action) => {
      state.ProviderList = action.payload[0];
      state.generalMsg = action.payload[1];
      state.Loading = false;
    },

    openDocumentTemplate: (state, action) => {
      state.openTemplate = action.payload;
    },
    setSessionForm: (state, action) => {
      state.sessionForm = action.payload;
    },
    setAskProvider: (state, action) => {
      state.askProvider = action.payload;
    },
  },
});

export const {
  GetRevision,
  SetRevision,
  GetFacilitySearchData,
  SetFacilitySearchData,
  GetAppointmentSearchData,
  SetAppointmentSearchData,
  SetAppointmentTenData,
  SetFacilitySearchNoResult,
  GetNoteUpdateData,
  GetIncomingFax,
  SetIncomingFax,
  GetSearchIncomingFax,
  SetSearchIncomingFax,
  GetInsertIncomingFax,
  SetInsertIncomingFax,
  GetOutgoingFax,
  SetOutgoingFax,
  GetInsertOutgoingFax,
  SetInsertOutgoingFax,
  GetProvDataFax,
  SetProvDataFax,
  GetSaveFaxQueue,
  SetSaveFaxQueue,
  GetEmptyProviderList,
  SetIncomingFaxDetails,
  GetIncomingFaxDetails,
  GetOutgoingFaxDetails,
  SetOutgoingFaxDetails,
  GetPreviewDownload,
  setPreviewDownload,
  setPreviewDownloadEmpty,
  GetPreviewDownload_2out,
  setPreviewDownload_2out,
  setPreviewDownload_2outEmpty,
  GetFaxProviderdetails,
  SetFaxProviderdetails,
  GetFaxAssign,
  SetFaxAssign,
  GetEmptyProviderListReferal,
  GetRefferal,
  SetRefferal,
  GetInsertRefferal,
  getProvidersList,
  setProvidersList,
  getFaxMailProviders,
  setFaxMailProviders,
  getPatList,
  setPatList,
  getReferalData,
  setReferalData,
  setTenReferalData,
  getReferalData1,
  setReferalData1,
  setTenReferalData1,
  GetRefMngmtPreview,
  SetRefMngmtPreview,
  GetUrlimg,
  SetUrlimg,
  getSeachProvider,
  setNoResult,
  setProviderList,
  getReferrerDiagnosis,
  setReferrerDiagnosis,
  getPatientDetails,
  setPatientDetails,
  referralInsertStart,
  changeAlertModal,
  getSingleReferral,
  setSingleReferral,
  startUpdateReferral,
  GetRefferalDelete,
  GetReferalContact,
  SetReferalContact,
  SetTenReferalContact,
  GetInsertReferalContact,
  GetSingleReferalContact,
  SetSingleReferalContact,
  GetUpdateReferalContact,
  GetDeleteReferalContact,
  GetRefContactemailcheck,
  SetRefContactemailcheck,
  GetRefContactphonecheck,
  SetRefContactphonecheck,
  GetRefContactfaxcheck,
  SetRefContactfaxcheck,
  GetRefContactnpicheck,
  SetRefContactnpicheck,
  GetRefContactemptyvalidation,
  SetRefContactemptyvalidation,
  SetRefContactemptyphone,
  SetRefContactemptyfax,
  SetRefContactemptynpi,
  getSendFaxMail,
  setSendFaxMail,
  getReferalDataOne,
  setReferalDataOne,
  getReferralDownload,
  setReferralDownload,
  emptyReferralDownload,
  getReferralPreview,
  setReferralPreview,
  getDeleteTempDocumentData,
  setDeleteTempDocumentData,
  changePreview,
  changeShowAdd,
  clearReferral,
  GetMsgList,
  SetGetMsgList,
  changeDisableSave,
  openDocumentTemplate,
  setSessionForm,
  setAskProvider,
  changeShowcontact,
} = RevisionSlice.actions;

export default RevisionSlice.reducer;
