import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Nav,
  NavItem,
  TopIcon,
  Span,
  Div,
  SubNavItem,
  UL,
  ListItem,
} from "./styles";
import { FaUsers } from "react-icons/fa";
import { BsCalendar4 } from "react-icons/bs";
import { IoWalletOutline } from "react-icons/io5";
import {
  FiFilePlus,
  FiSettings,
  FiChevronRight,
  FiChevronDown,
} from "react-icons/fi";
import PatientSubNav from "./PatientSubNav";
import ScheduleNav from "./ScheduleNav";
import BillingNav from "./BillingNav";
import ReportNav from "./ReportNav";
import SettingsNav from "./SettingsNav";
import { MdMonitor } from "react-icons/md";
import ExploreWidget from "./ExploreWidget";
import Greaterthan from "../../assets/images/Greaterthan.png";
import Greaterthanopp from "../../assets/images/Greaterthanopp.png";
import UnLoadScripts from "../popups/unLoadScript";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { GetNav } from "../../StateManagement/Reducers/ScheduleState";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const SideNav = () => {
  // ------------------------------ State and Variables start--------------------------------------------------- //
  const [isOpen, setIsOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState("");
  const [showPatientSubMenu, setShowPatientSubMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const timeoutRef = useRef(null);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const Patient_subroutes = [
    "/patient/patientdashboard/",
    "/patient/demographics/",
    "/patient/appointment/",
    "/patient/billandinsurance/",
    "/patient/documents/",
    "/patient/problems/",
    "/patient/allergies/",
    "/patient/medication/",
    "/patient/immunization/",
    "/patient/familyhistory/",
    "/patient/procedureorder/",
    "/patient/senderx/",
    "/patient/clientportalaccess/",
  ];
  const expired_days =
    localStorage.getItem("expired_days") &&
    Decrypt_Value(localStorage.getItem("expired_days"), "vozo");
  var NavData1 = useSelector((state) => state.Schedule?.NavData);
  var showAdd1 = useSelector((state) => state.Revision?.showAdd1);
  var showAdd2 = useSelector((state) => state.Revision?.showAdd2);
  var Showcontact = useSelector((state) => state.Revision?.Showcontact);
  const dispatch = useDispatch();

  const storedNames =
    localStorage.getItem("setroles") &&
    Decrypt_Value(localStorage.getItem("setroles"), "vozo");

  // ------------------------------ State and Variables end---------------------------------------------------//

  // ------------------------------ Functions----start------------------------------------------------------//
  const isOnPatientDashboard = Patient_subroutes.some((path) =>
    location.pathname.includes(path)
  );
  const handleMouseEnter = () => {
    dispatch(GetNav("iconopen"));

    setIsOpen(true);
    isOnPatientDashboard
      ? setShowPatientSubMenu(true)
      : setShowPatientSubMenu(false);
    if (
      (splitLocation[1] === "schedule" && splitLocation[2] === "nav") ||
      splitLocation[2] === "calender" ||
      (location.pathname === "/" && storedNames.appt)
    ) {
      setOpenSubMenu("schedule");
    } else {
      setOpenSubMenu(splitLocation[1]);
    }

    if (location.pathname === "/" && !storedNames.appt && storedNames.demo) {
      setOpenSubMenu("patient");
    } else if (
      location.pathname === "/" &&
      !storedNames.appt &&
      !storedNames.demo
      // && storedNames.bill
    ) {
      setOpenSubMenu("billing");
    } else if (
      location.pathname === "/" &&
      !storedNames.appt &&
      !storedNames.demo &&
      // !storedNames.bill &&
      storedNames.rep
    ) {
      setOpenSubMenu("report");
    } else if (
      location.pathname === "/" &&
      !storedNames.appt &&
      !storedNames.demo &&
      // !storedNames.bill &&
      !storedNames.rep &&
      storedNames.users
    ) {
      setOpenSubMenu("settings");
    }
  };
  const handleMouseLeave = () => {
    showPatientSubMenu ? setIsOpen(true) : setIsOpen(false);
    setOpenSubMenu("");
  };
  const handleClose = () => {
    dispatch(GetNav("iconclose"));
    isOnPatientDashboard && setShowPatientSubMenu(false);
    setIsOpen(false);
    setOpenSubMenu("");
  };

  const handleSubMenuClick = (menuName) => {
    setOpenSubMenu(openSubMenu === menuName ? "" : menuName);
  };

  const handleMouseEnterPatient = () => {
    setShowPatientSubMenu(true);
    setIsOpen(true);
    setOpenSubMenu("patient");
  };

  const handleMouseLeavePatient = () => {
    setShowPatientSubMenu(false);
    setIsOpen(false);
    setOpenSubMenu("");
  };

  const exploreMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsHovered(true);
  };

  // Timeout to work the hover effect
  const exploreMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsHovered(false);
    }, 200);
  };
  const handleInputChange = (iconName) => (e) => {
    dispatch(GetNav(iconName));
  };
  // ------------------------------ Functions----end---------------------------------------------------//

  // ------------------------------ useEffect----start---------------------------------------------------//
  useEffect(() => {
    if (isOnPatientDashboard && isOpen) {
      handleMouseEnterPatient();
      setShowPatientSubMenu(true);
    }
  }, [isOnPatientDashboard]);
  useEffect(() => {
    UnLoadScripts();
  }, []);
  useEffect(() => {
    if (NavData1 === "iconclose") {
      setIsOpen(false);
      setOpenSubMenu("");
    } else if (NavData1 === "iconopen") {
      setIsOpen(true);
      if (
        (splitLocation[1] === "schedule" && splitLocation[2] === "nav") ||
        splitLocation[2] === "calender" ||
        location.pathname === "/"
      ) {
        setOpenSubMenu("schedule");
      } else {
        setOpenSubMenu(splitLocation[1]);
      }
    }
  }, [NavData1]);
  // ------------------------------ useEffect----end---------------------------------------------------//

  return (
    <>
      <Nav
        // onMouseLeave={handleMouseLeave}
        isopen={isOpen ? "true" : "false"}
        isOnPatientDashboard={isOnPatientDashboard}
        zindex={
          isOnPatientDashboard && showPatientSubMenu
            ? "1040"
            : Showcontact || showAdd1 || showAdd2
            ? "0"
            : "11"
        }
      >
        {/* <Nav isOpen={isOpen} isOnPatientDashboard={isOnPatientDashboard}> */}
        <TopIcon>
          {isOpen ? (
            <img
              src={Greaterthanopp}
              alt="Close-icon"
              onClick={handleClose}
              style={{ marginLeft: "135px" }}
            ></img>
          ) : (
            <img
              src={Greaterthan}
              alt="menu-icon"
              onClick={handleMouseEnter}
              style={{ width: "24px" }}
            ></img>
          )}
        </TopIcon>
        <Div display="flex" width="100%" marginLeft={isOpen ? "40px" : "0px"}>
          <NavItem
            isopen={isOpen ? "true" : "false"}
            to={isOpen ? "/schedule" : "/schedule/nav/1"}
            color={
              splitLocation[1] === "schedule" ||
              (location.pathname === "/" && storedNames.appt)
                ? "#2c7be5"
                : "#2E2E2E80"
            }
            onClick={(e) => {
              if (isOpen) {
                e.preventDefault();
                handleSubMenuClick("schedule");
              }
            }}
          >
            {isOpen ? (
              <>
                <BsCalendar4 strokeWidth="0.5" />
                <Span>Schedule</Span>
              </>
            ) : (
              <>
                <BsCalendar4 strokeWidth="0.5" />
                <div style={{ fontSize: "12px" }}>Schedule</div>
              </>
            )}
          </NavItem>
          {isOpen && (
            <Div
              onClick={() => handleSubMenuClick("schedule")}
              cursor="pointer"
              margin="24px 36px 0px auto"
              color={
                splitLocation[1] === "schedule" ||
                (location.pathname === "/" && storedNames.appt)
                  ? "#2c7be5"
                  : "#2E2E2E80"
              }
            >
              {openSubMenu === "schedule" ? (
                <FiChevronDown className="svgSize" />
              ) : (
                <FiChevronRight className="svgSize" />
              )}
            </Div>
          )}
        </Div>
        {openSubMenu === "schedule" && <ScheduleNav />}
        <Div display="flex" width="100%" marginLeft={isOpen ? "40px" : "0px"}>
          <NavItem
            isopen={isOpen ? "true" : "false"}
            to={isOpen ? "/patient" : "/patient/nav/1"}
            id="pt_nav-lnk_mnu"
            onClick={(e) => {
              if (isOpen) {
                e.preventDefault();
                handleSubMenuClick("patient");
              }
            }}
            color={
              splitLocation[1] === "patient" ||
              (location.pathname === "/" &&
                !storedNames.appt &&
                storedNames.demo)
                ? "#2c7be5"
                : "#2E2E2E80"
            }
          >
            {isOpen ? (
              <>
                <FaUsers strokeWidth="0.5" />
                <Span>Patient</Span>
              </>
            ) : (
              <>
                <FaUsers strokeWidth="0.5" />
                <div style={{ fontSize: "12px" }}>Patient</div>
              </>
            )}
          </NavItem>
          {isOpen && (
            <Div
              onClick={() => handleSubMenuClick("patient")}
              cursor="pointer"
              margin="24px 36px 0px auto"
              color={
                splitLocation[1] === "patient" ||
                (location.pathname === "/" &&
                  !storedNames.appt &&
                  storedNames.demo)
                  ? "#2c7be5"
                  : "#2E2E2E80"
              }
            >
              {openSubMenu === "patient" ? (
                <FiChevronDown className="svgSize" />
              ) : (
                <FiChevronRight className="svgSize" />
              )}
            </Div>
          )}
        </Div>
        {openSubMenu === "patient" && (
          <Div display="flex" width="100%" paddingLeft="0.86em">
            <UL
              textAlign="left"
              width="100%"
              listStyleType="none"
              padding="0"
              margin="0"
              position="relative"
            >
              <ListItem
                padding="8px"
                margin="8px 0 0 0"
                onClick={handleInputChange("iconclose")}
              >
                <SubNavItem to="/patient/patientlist" exact>
                  Patients
                </SubNavItem>
              </ListItem>
              <ListItem
                padding="8px"
                margin="0"
                onClick={handleInputChange("iconopen")}
              >
                <SubNavItem to="/patient/patientflag" exact>
                  Patient Flag
                </SubNavItem>
              </ListItem>
              <ListItem
                padding="8px"
                margin="0"
                onClick={handleInputChange("iconopen")}
              >
                <SubNavItem to="/patient/formlibrary" exact>
                  Form Library
                </SubNavItem>
              </ListItem>
              <ListItem
                padding="8px"
                margin="0"
                onClick={handleInputChange("iconopen")}
              >
                <SubNavItem to="/patient/notetaking" exact>
                  Note Taking
                </SubNavItem>
              </ListItem>
              <ListItem
                padding="8px"
                margin="0"
                onClick={handleInputChange("iconopen")}
              >
                <SubNavItem to="/patient/managemacrobutton" exact>
                  Macro Buttons
                </SubNavItem>
              </ListItem>
              <ListItem
                padding="8px"
                margin="0"
                onClick={handleInputChange("iconopen")}
              >
                <SubNavItem to="/patient/patienteducation" exact>
                  Patient Education
                </SubNavItem>
              </ListItem>
              <ListItem
                padding="8px"
                margin="0"
                onClick={handleInputChange("iconclose")}
              >
                <SubNavItem to="/patient/formbuilder" exact>
                  Form Builder
                </SubNavItem>
              </ListItem>
              <ListItem
                padding="8px"
                margin="0"
                onClick={handleInputChange("iconopen")}
              >
                <SubNavItem to="/patient/IncomingFax" exact>
                  Incoming Fax
                </SubNavItem>
              </ListItem>
              <ListItem
                padding="8px"
                margin="0"
                onClick={handleInputChange("iconopen")}
              >
                <SubNavItem to="/patient/Outgoingfax" exact>
                  Outgoing Fax
                </SubNavItem>
              </ListItem>
              <ListItem
                padding="8px"
                margin="0"
                onClick={handleInputChange("iconclose")}
              >
                <SubNavItem to="/patient/electronicreports" exact>
                  Lab Reports
                </SubNavItem>
              </ListItem>
              <ListItem
                padding="8px"
                margin="0"
                onClick={handleInputChange("iconclose")}
              >
                <SubNavItem to="/patient/DocumentTemplates" exact>
                  Document Templates
                </SubNavItem>
              </ListItem>
              <ListItem
                padding="8px"
                margin="0"
                onClick={handleInputChange("iconopen")}
              >
                <SubNavItem to="/patient/formarchive" exact>
                  Form Archive
                </SubNavItem>
              </ListItem>
              <ListItem
                padding="8px"
                margin="0"
                onClick={handleInputChange("iconopen")}
              >
                <SubNavItem to="/patient/referralmanageMent" exact>
                  Referral ManageMent
                </SubNavItem>
              </ListItem>
            </UL>
          </Div>
        )}
        {/* <Div display="flex" width="100%" marginLeft={isOpen ? "40px" : "0px"}>
          <NavItem
            isopen={isOpen ? "true" : "false"}
            to={isOpen ? "/billing" : "/billing/nav/1"}
            onClick={(e) => {
              if (isOpen) {
                e.preventDefault();
                handleSubMenuClick("billing");
              }
            }}
            color={
              splitLocation[1] === "billing" ||
              (location.pathname === "/" &&
                !storedNames.appt &&
                !storedNames.demo &&
                storedNames.bill)
                ? "#2c7be5"
                : "#2E2E2E80"
            }
          >
            {isOpen ? (
              <>
                <IoWalletOutline strokeWidth="0.5" />
                <Span>Billing</Span>
              </>
            ) : (
              <>
                <IoWalletOutline strokeWidth="0.5" />
                <div style={{ fontSize: "12px" }}>Billing</div>
              </>
            )}
          </NavItem>
          {isOpen && (
            <Div
              onClick={() => handleSubMenuClick("billing")}
              cursor="pointer"
              margin="24px 36px 0px auto"
              color={
                splitLocation[1] === "billing" ||
                (location.pathname === "/" &&
                  !storedNames.appt &&
                  !storedNames.demo &&
                  storedNames.bill)
                  ? "#2c7be5"
                  : "#2E2E2E80"
              }
            >
              {openSubMenu === "billing" ? (
                <FiChevronDown className="svgSize" />
              ) : (
                <FiChevronRight className="svgSize" />
              )}
            </Div>
          )}
        </Div>
        {openSubMenu === "billing" && <BillingNav />} */}
        <Div display="flex" width="100%" marginLeft={isOpen ? "40px" : "0px"}>
          <NavItem
            isopen={isOpen ? "true" : "false"}
            id="rpt_nav-lnk_anch"
            to={isOpen ? "/report" : "/report/nav/1"}
            onClick={(e) => {
              if (isOpen) {
                e.preventDefault();
                handleSubMenuClick("report");
              }
            }}
            color={
              splitLocation[1] === "report" ||
              (location.pathname === "/" &&
                !storedNames.appt &&
                !storedNames.demo &&
                // !storedNames.bill &&
                storedNames.rep)
                ? "#2c7be5"
                : "#2E2E2E80"
            }
          >
            {isOpen ? (
              <>
                <FiFilePlus style={{ color: "inherit" }} />
                <Span>Reports</Span>
              </>
            ) : (
              <>
                <FiFilePlus style={{ color: "inherit" }} />
                <div style={{ fontSize: "12px" }}>Reports</div>
              </>
            )}
          </NavItem>
          {isOpen && (
            <Div
              onClick={() => handleSubMenuClick("report")}
              cursor="pointer"
              margin="24px 36px 0px auto"
              color={
                splitLocation[1] === "report" ||
                (location.pathname === "/" &&
                  !storedNames.appt &&
                  !storedNames.demo &&
                  // !storedNames.bill &&
                  storedNames.rep)
                  ? "#2c7be5"
                  : "#2E2E2E80"
              }
            >
              {openSubMenu === "report" ? (
                <FiChevronDown className="svgSize" />
              ) : (
                <FiChevronRight className="svgSize" />
              )}
            </Div>
          )}
        </Div>
        {openSubMenu === "report" && <ReportNav />}
        <Div display="flex" width="100%" marginLeft={isOpen ? "40px" : "0px"}>
          <NavItem
            isopen={isOpen ? "true" : "false"}
            to={isOpen ? "/settings" : "/settings/nav/1"}
            onClick={(e) => {
              if (isOpen) {
                e.preventDefault();
                handleSubMenuClick("settings");
              }
            }}
            color={splitLocation[1] === "settings" ? "#2c7be5" : "#2E2E2E80"}
          >
            {isOpen ? (
              <>
                <FiSettings style={{ color: "inherit" }} />
                <Span>Settings</Span>
              </>
            ) : (
              <>
                <FiSettings style={{ color: "inherit" }} />
                <div style={{ fontSize: "12px" }}>Settings</div>
              </>
            )}
          </NavItem>
          {isOpen && (
            <Div
              onClick={() => handleSubMenuClick("settings")}
              cursor="pointer"
              margin="24px 36px 0px auto"
              color={splitLocation[1] === "settings" ? "#2c7be5" : "#2E2E2E80"}
            >
              {openSubMenu === "settings" ? (
                <FiChevronDown className="svgSize" />
              ) : (
                <FiChevronRight className="svgSize" />
              )}
            </Div>
          )}
        </Div>
        {openSubMenu === "settings" && <SettingsNav />}
        <Div marginBottom="80px"></Div>
        {/* Explore Vozo widget */}
        {/* <Div onMouseEnter={exploreMouseEnter} onMouseLeave={exploreMouseLeave}> */}
        {/* <Div onMouseEnter={exploreMouseEnter}> */}
        {/* {!isOpen && (
            <Div
              width="24px"
              height="24px"
              backgroundColor="#2C7BE5"
              borderRadius="50%"
              position="absolute"
              bottom="73px"
              left="20px"
            >
              <MdMonitor color="white" />
            </Div>
          )} */}
        {/* {isHovered && expired_days >= 0 && (
            <ExploreWidget explore="exploreWidgetHover" />
          )}
        </Div>
        {isOpen && <ExploreWidget explore="explore-vozo-widget" />} */}
      </Nav>
      {/* {isOnPatientDashboard && showPatientSubMenu && (
        <PatientSubNav
          onMouseEnter={handleMouseEnterPatient}
          onMouseLeave={handleMouseLeavePatient}
        />
      )} */}
    </>
  );
};

export default SideNav;
