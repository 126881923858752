import { RRule, RRuleSet } from "rrule";
import moment from "moment";
export const dayBinding = (DaysArray) => {
  return [
    DaysArray.includes("1") ? RRule.SU : false,
    DaysArray.includes("2") ? RRule.MO : false,
    DaysArray.includes("3") ? RRule.TU : false,
    DaysArray.includes("4") ? RRule.WE : false,
    DaysArray.includes("5") ? RRule.TH : false,
    DaysArray.includes("6") ? RRule.FR : false,
    DaysArray.includes("7") ? RRule.SA : false,
  ];
};

export const repeatArray = (Values, repeatFor) => {
  var temp = [];
  var repeatVal = [];
  var indexVal = 0;
  for (var i = 0; i < Values.length; i++) {
    const rruleSetStart = new RRuleSet();
    const rruleSetEnd = new RRuleSet();
    var date = new Date(
      Values[i].repeatEndDate ? Values[i].repeatEndDate : Values[i].pc_endDate
    );

    date.setDate(date.getDate() + 1);
    var startArray = {};
    var endArray = {};
    if (Values[i].repeatValue === "Monthly_By_Day") {
      rruleSetStart.rrule(
        new RRule({
          freq: RRule.MONTHLY,
          dtstart: new Date(Values[i].start),
          until: date,
          bysetpos: parseInt(Values[i].interval),
          byweekday: dayBinding(Values[i].customDate),
          // byweekday: [RRule.MO.nth(3), RRule.FR.nth(3)],
          // interval: parseInt(Values[i].interval),
          interval: parseInt(Values[i].interval_per_month),
        })
      );
      if (Values[i].exDateStart.length !== 0) {
        for (var e = 0; e < Values[i].exDateStart.length; e++) {
          rruleSetStart.exdate(new Date(Values[i].exDateStart[e]));
        }
      }

      startArray = rruleSetStart.all();
      rruleSetEnd.rrule(
        new RRule({
          freq: RRule.MONTHLY,
          dtstart: new Date(Values[i].end),
          until: date,
          byweekday: dayBinding(Values[i].customDate),
          bysetpos: parseInt(Values[i].interval),
          interval: parseInt(Values[i].interval_per_month),
          // byweekday: [RRule.MO.nth(3), RRule.FR.nth(3)],
          // interval: parseInt(Values[i].interval),
        })
      );
      if (Values[i].exDateEnd.length !== 0) {
        for (var r = 0; r < Values[i].exDateEnd.length; r++) {
          rruleSetEnd.exdate(new Date(Values[i].exDateEnd[r]));
        }
      }
      endArray = rruleSetEnd.all();
    } else if (Values[i].repeatValue === "Weekly_By_Day") {
      rruleSetStart.rrule(
        new RRule({
          freq: RRule.WEEKLY,
          dtstart: new Date(Values[i].start),
          byweekday: dayBinding(Values[i].customDate),
          until: date,
          interval: parseInt(Values[i].interval),
        })
      );

      if (Values[i].exDateStart.length !== 0) {
        for (var j = 0; j < Values[i].exDateStart.length; j++) {
          rruleSetStart.exdate(new Date(Values[i].exDateStart[j]));
        }
      }

      startArray = rruleSetStart.all();

      rruleSetEnd.rrule(
        new RRule({
          freq: RRule.WEEKLY,
          dtstart: new Date(Values[i].end),
          byweekday: dayBinding(Values[i].customDate),

          until: date,
          interval: parseInt(Values[i].interval),
        })
      );
      if (Values[i].exDateEnd.length !== 0) {
        for (var k = 0; k < Values[i].exDateEnd.length; k++) {
          rruleSetEnd.exdate(new Date(Values[i].exDateEnd[k]));
        }
      }
      endArray = rruleSetEnd.all();
    } else if (Values[i].repeatValue === "Weekly") {
      rruleSetStart.rrule(
        new RRule({
          freq: RRule.WEEKLY,
          dtstart: new Date(Values[i].start),
          until: date,
          interval: parseInt(Values[i].interval),
        })
      );

      if (Values[i].exDateStart.length !== 0) {
        for (var j = 0; j < Values[i].exDateStart.length; j++) {
          rruleSetStart.exdate(new Date(Values[i].exDateStart[j]));
        }
      }

      startArray = rruleSetStart.all();

      rruleSetEnd.rrule(
        new RRule({
          freq: RRule.WEEKLY,
          dtstart: new Date(Values[i].end),
          until: date,
          interval: parseInt(Values[i].interval),
        })
      );
      if (Values[i].exDateEnd.length !== 0) {
        for (var k = 0; k < Values[i].exDateEnd.length; k++) {
          rruleSetEnd.exdate(new Date(Values[i].exDateEnd[k]));
        }
      }
      endArray = rruleSetEnd.all();
    } else if (Values[i].repeatValue === "Day") {
      rruleSetStart.rrule(
        new RRule({
          freq: RRule.DAILY,
          dtstart: new Date(Values[i].start),
          until: date,
          interval: parseInt(Values[i].interval),
        })
      );

      if (Values[i].exDateStart.length !== 0) {
        for (var l = 0; l < Values[i].exDateStart.length; l++) {
          rruleSetStart.exdate(new Date(Values[i].exDateStart[l]));
        }
      }

      startArray = rruleSetStart.all();

      rruleSetEnd.rrule(
        new RRule({
          freq: RRule.DAILY,
          dtstart: new Date(Values[i].end),
          until: date,
          interval: parseInt(Values[i].interval),
        })
      );
      if (Values[i].exDateEnd.length !== 0) {
        for (var p = 0; p < Values[i].exDateEnd.length; p++) {
          rruleSetEnd.exdate(new Date(Values[i].exDateEnd[p]));
        }
      }
      endArray = rruleSetEnd.all();
    } else if (Values[i].repeatValue === "WorkDay") {
      rruleSetStart.rrule(
        new RRule({
          freq: RRule.DAILY,
          byweekday: dayBinding(["2", "3", "4", "5", "6"]),
          dtstart: new Date(Values[i].start),
          until: date,
          interval: parseInt(Values[i].interval),
        })
      );
      if (Values[i].exDateStart.length !== 0) {
        for (var q = 0; q < Values[i].exDateStart.length; q++) {
          rruleSetStart.exdate(new Date(Values[i].exDateStart[q]));
        }
      }

      startArray = rruleSetStart.all();
      rruleSetEnd.rrule(
        new RRule({
          freq: RRule.DAILY,
          byweekday: dayBinding(["2", "3", "4", "5", "6"]),
          dtstart: new Date(Values[i].end),
          until: date,
          interval: parseInt(Values[i].interval),
        })
      );
      if (Values[i].exDateEnd.length !== 0) {
        for (var w = 0; w < Values[i].exDateEnd.length; w++) {
          rruleSetEnd.exdate(new Date(Values[i].exDateEnd[w]));
        }
      }
      endArray = rruleSetEnd.all();
    } else if (Values[i].repeatValue === "Monthly") {
      rruleSetStart.rrule(
        new RRule({
          freq: RRule.MONTHLY,
          dtstart: new Date(Values[i].start),
          until: date,
          interval: parseInt(Values[i].interval),
        })
      );
      if (Values[i].exDateStart.length !== 0) {
        for (var e = 0; e < Values[i].exDateStart.length; e++) {
          rruleSetStart.exdate(new Date(Values[i].exDateStart[e]));
        }
      }

      startArray = rruleSetStart.all();
      rruleSetEnd.rrule(
        new RRule({
          freq: RRule.MONTHLY,
          dtstart: new Date(Values[i].end),
          until: date,
          interval: parseInt(Values[i].interval),
        })
      );
      if (Values[i].exDateEnd.length !== 0) {
        for (var r = 0; r < Values[i].exDateEnd.length; r++) {
          rruleSetEnd.exdate(new Date(Values[i].exDateEnd[r]));
        }
      }
      endArray = rruleSetEnd.all();
    } else if (Values[i].repeatValue === "Yearly") {
      rruleSetStart.rrule(
        new RRule({
          freq: RRule.YEARLY,
          dtstart: new Date(Values[i].start),
          until: date,
          interval: parseInt(Values[i].interval),
        })
      );
      if (Values[i].exDateStart.length !== 0) {
        for (var t = 0; t < Values[i].exDateStart.length; t++) {
          rruleSetStart.exdate(new Date(Values[i].exDateStart[t]));
        }
      }

      startArray = rruleSetStart.all();
      rruleSetEnd.rrule(
        new RRule({
          freq: RRule.YEARLY,
          dtstart: new Date(Values[i].end),
          until: date,
          interval: parseInt(Values[i].interval),
        })
      );
      if (Values[i].exDateEnd.length !== 0) {
        for (var y = 0; y < Values[i].exDateEnd.length; y++) {
          rruleSetEnd.exdate(new Date(Values[i].exDateEnd[y]));
        }
      }
      endArray = rruleSetEnd.all();
    } else if (Values[i].repeatValue === "Custom...") {
      rruleSetStart.rrule(
        new RRule({
          freq: RRule.DAILY,
          byweekday: dayBinding(Values[i].customDate),
          dtstart: new Date(Values[i].start),
          until: date,
        })
      );
      if (Values[i].exDateStart.length !== 0) {
        for (var d = 0; d < Values[i].exDateStart.length; d++) {
          rruleSetStart.exdate(new Date(Values[i].exDateStart[d]));
        }
      }

      startArray = rruleSetStart.all();
      rruleSetEnd.rrule(
        new RRule({
          freq: RRule.DAILY,
          byweekday: dayBinding(Values[i].customDate),
          dtstart: new Date(Values[i].end),
          until: date,
        })
      );
      if (Values[i].exDateEnd.length !== 0) {
        for (var ab = 0; ab < Values[i].exDateEnd.length; ab++) {
          rruleSetEnd.exdate(new Date(Values[i].exDateEnd[ab]));
        }
      }
      endArray = rruleSetEnd.all();
    }
    if (repeatFor === "calenderAppointment") {
      if (startArray.length > 1) {
        for (var ac = 0; ac < startArray.length; ac++) {
          indexVal = indexVal + 1;
          var tempArray = {};
          tempArray.start_url = Values[i].start_url;
          tempArray.Id = Values[i].Id;
          tempArray.EndTime = Values[i].EndTime;
          tempArray.categoryColor = Values[i].categoryColor;
          tempArray.pc_pid = Values[i].pc_pid;
          tempArray.Subject = Values[i].Subject;
          tempArray.encounter = Values[i].encounter;
          tempArray.IsBlock = Values[i].IsBlock;
          tempArray.exDateStart = Values[i].exDateStart;
          tempArray.exDateEnd = Values[i].exDateEnd;
          tempArray.repeatOn = Values[i].repeatOn;
          tempArray.repeatAfter = Values[i].repeatAfter;
          tempArray.customDate = Values[i].customDate;
          tempArray.repeatValue = Values[i].repeatValue;
          tempArray.value = Values[i].value;
          tempArray.CategoryColor = Values[i].CategoryColor;
          tempArray.ConferenceId = Values[i].ConferenceId;
          tempArray.Status = Values[i].Status;
          tempArray.appt_type = Values[i].appt_type;
          tempArray.id = Values[i].id;
          tempArray.label = Values[i].label;
          tempArray.pc_endDate = Values[i].pc_endDate;
          tempArray.pc_endTime = Values[i].pc_endTime;
          tempArray.pc_eventDate = Values[i].pc_eventDate;
          tempArray.pc_recurrspec = Values[i].pc_recurrspec;
          tempArray.pc_recurrtype = Values[i].pc_recurrtype;
          tempArray.pc_startTime = Values[i].pc_startTime;
          tempArray.pid = Values[i].pid;
          tempArray.provider_name = Values[i].provider_name;
          tempArray.time_Zone = Values[i].time_Zone;
          tempArray.neverIs = Values[i].neverIs;
          tempArray.title = Values[i].title;

          tempArray.start = startArray[ac];
          tempArray.end = endArray[ac];
          tempArray.end1 = endArray[ac];
          tempArray.resourceId = Values[i].resourceId;
          tempArray.recurrance = Values[i].recurrance;
          tempArray.Accepted = Values[i].Accepted;
          tempArray.pc_duration = Values[i].pc_duration;
          tempArray.categoryTitle = Values[i].categoryTitle;
          tempArray.index = indexVal;
          repeatVal.push(tempArray);
        }
      } else {
        indexVal = indexVal + 1;
        temp[i] = Values[i];
        temp[i].start = new Date(Values[i].start);
        temp[i].end = new Date(Values[i].end);
        temp[i].index = indexVal;
      }
    } else if (repeatFor === "FutureAppointment") {
      if (startArray.length > 0) {
        for (var j = 0; j < startArray.length; j++) {
          indexVal = indexVal + 1;
          var tempArray2 = {};

          tempArray2.EndTime = Values[i].EndTime;
          tempArray2.pc_pid = Values[i].pc_pid;
          tempArray2.pc_catid = Values[i].pc_catid;
          tempArray2.pc_eid = Values[i].pc_eid;
          tempArray2.pc_aid = Values[i].pc_aid;
          tempArray2.userfname = Values[i].userfname;
          tempArray2.userlname = Values[i].userlname;
          tempArray2.pc_title = Values[i].pc_title;
          tempArray2.facility_name = Values[i].facility_name;
          tempArray2.pc_facility = Values[i].pc_facility;
          tempArray2.pc_apptstatus = Values[i].pc_apptstatus;
          tempArray2.pc_duration = Values[i].pc_duration;
          tempArray2.note_status = Values[i].note_status;
          tempArray2.encounter = Values[i].encounter;
          tempArray2.exDateStart = Values[i].exDateStart;
          tempArray2.exDateEnd = Values[i].exDateEnd;
          tempArray2.customDate = Values[i].customDate;
          tempArray2.repeatValue = Values[i].repeatValue;
          tempArray2.pc_endDate = Values[i].pc_endDate;
          tempArray2.pc_endTime = Values[i].pc_endTime;
          tempArray2.pc_eventDate = Values[i].pc_eventDate;
          tempArray2.pc_recurrspec = Values[i].pc_recurrspec;
          tempArray2.pc_recurrtype = Values[i].pc_recurrtype;
          tempArray2.pc_startTime = Values[i].pc_startTime;
          tempArray2.title = Values[i].title;
          tempArray2.appt_status = Values[i].appt_status;
          tempArray2.pc_duration = Values[i].pc_duration;
          tempArray2.categoryTitle = Values[i].categoryTitle;
          let fDate = startArray[j].toString();
          let FromDate = fDate.split(" ");

          let formattedDate = FromDate[3].concat(
            " ",
            FromDate[2],
            " ",
            FromDate[1]
          );

          let parsedDate = moment(formattedDate, "YYYY DD MMM").format(
            "YYYY-MM-DD"
          );

          let AppDateValidation = FromDate[3].concat(
            " ",
            FromDate[2],
            " ",
            FromDate[1],
            " ",
            Values[i].pc_startTime
          );

          let Validationdate = moment(
            AppDateValidation,
            "YYYY MM MMM hh:mm A"
          ).format("YYYY-MM-DD HH:mm:ss");

          tempArray2.ApptFromDate = parsedDate;
          tempArray2.ApptFromDateWithtime = Validationdate;
          tempArray2.start = startArray[j];
          tempArray2.end = endArray[j];
          tempArray2.recurrance = Values[i].recurrance;
          tempArray2.index = indexVal;
          repeatVal.push(tempArray2);
        }
      } else {
        indexVal = indexVal + 1;
        temp[i] = Values[i];
        temp[i].start = new Date(Values[i].start);
        temp[i].end = new Date(Values[i].end);
        temp[i].index = indexVal;
      }
    } else if (repeatFor === "ProductivityReport") {
      if (startArray.length > 0) {
        for (var j = 0; j < startArray.length; j++) {
          indexVal = indexVal + 1;
          var tempArray3 = {};

          tempArray3.pc_duration = Values[i].pc_duration;
          tempArray3.office = Values[i].office;
          tempArray3.head = Values[i].head;

          let fDate = startArray[j].toString();
          let FromDate = fDate.split(" ");
          let ApptFromDate = FromDate[3].concat(
            " ",
            FromDate[2],
            " ",
            FromDate[1]
          );

          let Validationdate = moment(ApptFromDate, "YYYY DD MMM").format(
            "YYYY/MM/DD"
          );
          tempArray3.FromdateValidation = Validationdate;

          tempArray3.start = startArray[j];
          tempArray3.end = endArray[j];
          tempArray3.recurrance = Values[i].recurrance;
          tempArray3.index = indexVal;
          repeatVal.push(tempArray3);
        }
      } else {
        indexVal = indexVal + 1;
        temp[i] = Values[i];
        temp[i].start = new Date(Values[i].start);
        temp[i].end = new Date(Values[i].end);
        temp[i].index = indexVal;
      }
    } else if (repeatFor === "telehealthReport") {
      if (startArray.length > 0) {
        for (var j = 0; j < startArray.length; j++) {
          indexVal = indexVal + 1;
          var tempArray4 = {};

          tempArray4.app_date_time = Values[i].app_date_time;
          tempArray4.pat_fullname = Values[i].pat_fullname;
          tempArray4.user_fullname = Values[i].user_fullname;
          tempArray4.facility_name = Values[i].facility_name;
          tempArray4.visit_status = Values[i].visit_status;
          tempArray4.duration = Values[i].duration;
          tempArray4.appt_stat = Values[i].appt_stat;
          tempArray4.startTime = Values[i].startTime;
          tempArray4.pc_recurrtype = Values[i].pc_recurrtype;
          tempArray4.pc_duration = Values[i].pc_duration;
          tempArray4.categoryTitle = Values[i].categoryTitle;
          let fDate = startArray[j].toString();
          let FromDate = fDate.split(" ");
          let ApptFromDate = FromDate[3].concat(
            " ",
            FromDate[2],
            " ",
            FromDate[1]
          );

          let AppDateValidation = FromDate[3].concat(
            " ",
            FromDate[2],
            " ",
            FromDate[1],
            " ",
            Values[i].pc_startTime
          );

          let Validationdate = moment(ApptFromDate).format("YYYY/MM/DD");
          let ValidationdateSort = moment(AppDateValidation).format(
            "YYYY-MM-DD HH:mm:ss"
          );

          tempArray4.ApptFromDateWithtime = Validationdate;

          tempArray4.FromdateValidation = Validationdate;

          let sourceDate = Validationdate.split("/");
          let RepeatDate = sourceDate.join("-");

          tempArray4.repeatDate = RepeatDate + " " + Values[i].startTime;
          tempArray4.start = startArray[j];
          tempArray4.end = endArray[j];
          tempArray4.recurrance = Values[i].recurrance;
          tempArray4.index = indexVal;
          repeatVal.push(tempArray4);
        }
      } else {
        indexVal = indexVal + 1;
        temp[i] = Values[i];
        temp[i].start = new Date(Values[i].start);
        temp[i].end = new Date(Values[i].end);
        temp[i].index = indexVal;
      }
    } else {
      if (startArray.length > 1) {
        for (var ad = 0; ad < startArray.length; ad++) {
          indexVal = indexVal + 1;
          var tempArray1 = {};

          tempArray1.start = startArray[ad];
          tempArray1.end = endArray[ad];

          tempArray1.Appttime = Values[i].Appttime;
          tempArray1.Closetime = Values[i].Closetime;
          tempArray1.MobNo = Values[i].MobNo;
          tempArray1.Pat_Prof = Values[i].Pat_Prof;
          tempArray1.Patname = Values[i].Patname;
          tempArray1.appt_type = Values[i].appt_type;
          tempArray1.eventDate = Values[i].eventDate;
          tempArray1.id = Values[i].id;
          tempArray1.pc_apptstatus = Values[i].pc_apptstatus;
          tempArray1.pc_endDate = Values[i].pc_endDate;
          tempArray1.pc_recurrspe = Values[i].pc_recurrspe;
          tempArray1.pc_recurrtyp = Values[i].pc_recurrtyp;
          tempArray1.repeatValue = Values[i].repeatValue;
          tempArray1.repeatAfter = Values[i].repeatAfter;
          tempArray1.repeatOn = Values[i].repeatOn;
          tempArray1.index = indexVal;
          tempArray1.pc_startTime = Values[i].pc_startTime;
          tempArray1.pc_endTime = Values[i].pc_endTime;
          tempArray1.recurrance = Values[i].recurrance;
          tempArray1.Accepted = Values[i].Accepted;
          tempArray1.pc_duration = Values[i].pc_duration;
          tempArray1.categoryTitle = Values[i].categoryTitle;
          repeatVal.push(tempArray1);
        }
      } else {
        indexVal = indexVal + 1;
        temp[i] = Values[i];
        temp[i].start = new Date(Values[i].start);
        temp[i].end = new Date(Values[i].end);
        temp[i].index = indexVal;
      }
    }
    /// repeat date concept end
    // create repeat appointment
  }

  return [temp, repeatVal];
};

export const MeetArray = (
  filtered,
  updateCurrentAppt,
  updateIsCurrentAppt,
  updateAppointmentList,
  updateRightBlockStatus,
  updateAppStatus
) => {
  var cur = 0;
  var upcomm = 0;
  var currAppointment = [];
  var currDayApptList = [];
  var upcommAppt = [];
  var upcommDayApptList = [];
  var prevDate = new Date();
  for (var k = 0; k < filtered.length; k++) {
    if (moment(filtered[k].start).isSame(moment(), "day")) {
      var now = new Date();
      var target = new Date(filtered[k].start);
      if (
        now.getHours() >= target.getHours() &&
        now.getMinutes() >= target.getMinutes()
      ) {
        continue;
      }
    }
    if (moment(filtered[k].start).isSame(moment(), "day") && cur === 0) {
      currAppointment = filtered[k];
      cur++;
    } else if (moment(filtered[k].start).isSame(moment(), "day") && cur === 0) {
      currAppointment = filtered[k];
      cur++;
    } else if (moment(filtered[k].start).isSame(moment(), "day") && cur !== 0) {
      currDayApptList.push(filtered[k]);
    } else if (
      moment(filtered[k].start).isAfter(moment(), "day") &&
      upcomm === 0
    ) {
      upcommAppt = filtered[k];
      upcomm++;
    } else if (
      moment(filtered[k].start).isAfter(moment(), "day") &&
      upcomm !== 0
    ) {
      if (moment(prevDate).isSame(moment(filtered[k].start), "day")) {
        filtered[k].Type = false;
      } else {
        prevDate = filtered[k].start;
        filtered[k].Type = true;
      }

      upcommDayApptList.push(filtered[k]);
    }
  }
  if (currAppointment.length !== 0) {
    // if current day appointment is not empty on left side
    let apptData = {
      //set the params
      name: currAppointment.Patname,
      mobNo: currAppointment.MobNo,
      apptType: currAppointment.appt_type,
      apptDate: currAppointment.eventDate,
      apptTime: currAppointment.Appttime,
      proPic: currAppointment.Pat_Prof,
      start: currAppointment.start,
      end: currAppointment.end,
    };
    updateCurrentAppt(apptData); // values are to zustand
    updateIsCurrentAppt(true); // this flag will  enable the left block client data

    updateAppointmentList(currDayApptList); // value for right block
    updateRightBlockStatus("apptList"); // switch values for right block
    if (currAppointment.length !== 0 && currDayApptList.length === 0) {
      updateRightBlockStatus("upComingAppt"); // switch values for right block
    } else {
      updateRightBlockStatus("apptList"); // switch values for right block
    }
    updateAppStatus("current"); // right block header text ternary condition
  } else if (upcommAppt.length !== 0) {
    // if current next appointment is not empty on left side
    let apptData = {
      id: upcommAppt.id,
      name: upcommAppt.Patname,
      mobNo: upcommAppt.MobNo,
      apptType: upcommAppt.appt_type,
      apptDate: upcommAppt.eventDate,
      apptTime: upcommAppt.Appttime,
      start: upcommAppt.start,
      end: upcommAppt.end,
    };
    updateCurrentAppt(apptData);
    updateIsCurrentAppt(true);
    updateAppointmentList(upcommDayApptList);

    if (upcommAppt.length !== 0 && upcommDayApptList.length === 0) {
      updateRightBlockStatus("noData");
    } else {
      updateRightBlockStatus("apptList");
    }
    updateAppStatus("upcoming");
  }
};

export const UpcomingSessionArray = (
  filtered,
  AppStatusUpd,
  updateAppointmentList,
  setAppointmentEmpty
) => {
  var tempArray = [];
  var prevDate = new Date();

  for (var k = 0; k < filtered.length; k++) {
    if (moment(filtered[k].start).isAfter(moment(), "day")) {
      if (moment(prevDate).isSame(moment(filtered[k].start), "day")) {
        filtered[k].Type = false;
      } else {
        prevDate = filtered[k].start;
        filtered[k].Type = true;
      }
      tempArray.push(filtered[k]);
    }
  }
  if (tempArray.length !== 0) {
    AppStatusUpd("upcoming");
    updateAppointmentList(tempArray);
  } else {
    setAppointmentEmpty(true);
    updateAppointmentList([]);
  }
};
